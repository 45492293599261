<template>
<div class="new__table new__table_2" v-loading="loadingData">
    <div class="scheduler">
        <div class="schedule">
            <div class="crm-schedule-box d-inline-flex w-100">
                <table class="table table-bordered nurseOffice_table crm-schedule-table table-bordered">
                    <thead>
                        <tr>
                            <th>{{ $t('message.drug')  }} {{isended}}</th>
                            <th v-for="date in dateList" :key="date.val + '-datess'">
                                {{ date.title }}
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="dailyTreatment in list" :key="dailyTreatment.id">
                            <td class="left_likarstovas">
                                <div class="modalCells">
                                    <img src="img/icon/vaccine.svg" alt />
                                    <span class="insttru" @click="showItems(dailyTreatment)">{{ $t('message.drug_instruction') }} {{lastHistory}}</span>
                                </div>
                            </td>
                            <td v-for="date in dateList" :key="date.val + '-dat  '" class="dataDay">
                                <template v-if="hasLek(date,dailyTreatment)">
                                    <template v-if="dailyTreatment.is_edit == 1">
                                        <template v-for="i in dailyTreatment.count">
                                            <div v-if="isended ? isended : '' != 0" disabled class="modalCells is_edit" :class="classObject(date,dailyTreatment,i)" :key="i + '-count'"></div>
                                            <div v-else class="modalCells is_edit" :class="classObject(date,dailyTreatment,i)" :key="i + '-count'" @click="openDialog(date,dailyTreatment,i)"></div>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <template v-for="i in dailyTreatment.count">
                                            <div v-if="isended ? isended : '' != 0" disabled class="modalCells" :class="classObject(date,dailyTreatment,i)" :key="i + '-count'"></div>
                                            <div v-else disabled class="modalCells" :class="classObject(date,dailyTreatment,i)" :key="i + '-count'" @click="openDialog(date,dailyTreatment,i)"></div>
                                        </template>
                                    </template>
                                </template>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <el-dialog class="xModal modalLik" :title="$t('message.drug_instruction')" width="40%" :visible.sync="isItemsModelVisible" :before-close="onCloseDialog" @opened="$refs['showTreatment'].afterOpen()">
            <CrmShow :model="selectedModel" ref="showTreatment" />
        </el-dialog>
        <el-dialog class="modal_lea xModal" :title="$t('message.info_treatment')" width="30%" :visible.sync="dialogTableVisible2" @closed="closeDialog()" @opened="$refs['modalAdd'].afterOpened()">
            <ModalAdd :selected="selectedModel_2" ref="modalAdd" @c-close="closeDialog" />
        </el-dialog>
    </div>
</div>
</template>

<script>
import {
    mapGetters,
    mapActions
} from "vuex";
import CrmShow from "./components/crm-show";
import ModalAdd from "./components/modalAdd";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
export default {
    mixins: [list, drawer],
    props: ['number', 'isended'],
    name: "dailyTreatment",
    components: {
        CrmShow,
        ModalAdd,
    },
    data() {
        return {

            loadingData: false,
            isLoadingItems: false,
            dialogTableVisible2: false,
            isItemsModelVisible: false,
            selectedModel: {},
            selectedModel_2: {},
            filterForm: {},
            drawer: {
                create: {
                    name: "create",
                    status: false,
                    component: 'componentDrawerCreate'
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            indexList: 'dailyTreatmentIndeces/list',
            list: 'nurceOffices/list',
            dateList: 'nurceOffices/dateList',
            columns: "nurceOffices/columns",
            pagination: "nurceOffices/pagination",
            filter: "nurceOffices/filter",
            sort: "nurceOffices/sort",
            dailyTreatment: 'nurceOffices/model',
            lastHistory: 'dailyTreatments/lastHistory',

        }),
        actions: function () {
            return ['edit', 'delete'];
        }
    },
    async mounted() {
        $(".new__table_2 .crm-schedule-table tbody").scroll(function (e) {
            //detect a scroll event on the tbody
            $(".new__table_2 .crm-schedule-table thead").css(
                "left",
                -$(".new__table_2  .crm-schedule-table tbody").scrollLeft()
            ); //fix the thead relative to the body scrolling
            $(".new__table_2 .crm-schedule-table thead th:nth-child(1)").css(
                "left",
                $(".new__table_2 .crm-schedule-table tbody").scrollLeft()
            ); //fix the first cell of the header
            $(".new__table_2 .crm-schedule-table tbody td:nth-child(1)").css(
                "left",
                $(".new__table_2 .crm-schedule-table tbody").scrollLeft()
            ); //fix the first column of tdbody
        });
    },
    methods: {
        ...mapActions({
            updateList: 'nurceOffices/index',
            updateSort: "nurceOffices/updateSort",
            updateFilter: "nurceOffices/updateFilter",
            updateColumn: "nurceOffices/updateColumn",
            showDate: 'nurceOffices/show',
            updatePagination: "nurceOffices/updatePagination",
            empty: 'nurceOffices/empty',
            delete: 'nurceOffices/destroy',
            showNumber: 'nurceOffices/showNumber',
            refreshData: 'nurceOffices/refreshData',
        }),
        hasLek(date, dailyT) {
            if (dailyT.end_date) {
                let days = dailyT.end_date;
                if (_.isArray(days) && days.includes(date.val)) {
                    return true;
                }
            }
        },
        fetchData() {},
        fetchDataAny() {
            this.loadingData = true;
            this.showNumber({
                    number: this.number
                })
                .then(res => {
                    this.loadingData = false;
                }).catch(err => {
                    this.loadingData = false;
                    this.$alert(err)
                });
        },
        classObject: function (date, dt, i) {
            let a = this.checkIndex(date, dt, i);
            return [{
                'on_lics': (a == 'plus' || a == 'continue'),
                'od_lics': (a == 'minus'),
                'off_lics': (a == 'defect'),
                'off_user': (a == 'reject')
            }, 'height' + dt.count];
        },
        refresh() {
            this.refreshData()
                .then(res => {
                    this.filterForm = JSON.parse(JSON.stringify(this.filter));
                })
                .catch(err => {

                })
        },
        openDialog(date, dt, time) {
            if (dt.dailyTreatmentIndeces) {
                let days = dt.dailyTreatmentIndeces;
                if (_.isArray(days)) {
                    let item = _.find(days, function (o) {
                        return (o.day == date.val && o.time == time)
                    });
                    if (item) {
                        this.dialogTableVisible2 = true;
                        this.selectedModel_2 = item;
                    }
                }
            }
        },
        closeDialog() {
            this.$refs.modalAdd.clean()
            this.dialogTableVisible2 = false
            this.fetchDataAny()
        },
        checkIndex(date, dt, i) {
            if (dt.dailyTreatmentIndeces) {
                let indeces = dt.dailyTreatmentIndeces;
                if (_.isArray(indeces)) {
                    let item = _.find(indeces, function (o) {
                        return (o.day == date.val && o.time == i)
                    });
                    return item ? item.index : 'minus';
                }
            }
        },
        showItems(model) {
            this.isItemsModelVisible = true;
            this.selectedModel = model;
        },
        onCloseDialog(done) {
            done();
            this.empty();
            this.isLoadingItems = false;
        },
        emptyModel() {
            this.empty()
        },
    }
};
</script>

<style lang="css">
.new__table .scheduler table.crm-schedule-table>tbody tr td:nth-child(1) {
    flex-direction: column;
}

.modalCells {
    flex: 1;
}

.is_edit {
    background: #f56c6c8a !important;
}

.ta_button {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 3px;
}

.ta_button .el-button {
    width: 48%;
    margin: 0px;
}

.new__table.new__table_2 tbody::-webkit-scrollbar {
    width: 10px;
    /* width of the entire scrollbar */
}

.new__table.new__table_2 tbody::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* color of the tracking area */
}

.new__table.new__table_2 tbody::-webkit-scrollbar-thumb {
    background-color: #d2d2d2;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid #f1f1f1;
    /* creates padding around scroll thumb */
}

body::-webkit-scrollbar {
    width: 15px;
    /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
    background: #fff;
    /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
    background-color: #d2d2d2;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid #fff;
    /* creates padding around scroll thumb */
}
</style>
